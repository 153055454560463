@import '../sizing.scss';

.c-voucher-alert {
  .alert-wrapper {
    min-width: 21rem !important;

    @media screen and (max-width: $break-sm) {
      min-width: 85vw !important;
    }
  }
}
