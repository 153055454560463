:root {
  ion-toast {
    --background: var(--color-white) !important;
    --border-radius: 100px;
    --box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14);
    --color: var(--color-black);
    --width: max-content;
    --max-width: 90%;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }
}

.c-toast {
  &--success {
    --color: var(--color-green) !important;
  }

  &--error {
    --color: var(--color-red) !important;
  }
}
