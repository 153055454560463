@import '../sizing.scss';
@import '../variables.scss';

.c-card {
  position: relative;
  padding: 1.5 * $size-base;

  background-color: var(--color-secondary-100);
  border-radius: $size-base;

  &--sm {
    padding: $size-base;

    .c-card__body {
      &--white {
        margin: #{-0.5 * $size-base};
        margin-top: $size-md;
        margin-bottom: -$size-xs;
        padding: $size-md;
      }
    }
  }

  &--gray {
    background: var(--color-gray-100);
  }

  &--illustration {
    display: flex;
  }

  &--image {
    overflow: hidden;

    * {
      position: relative;
      z-index: 1;
    }
  }

  &:not([open]) {
    .c-card__action--hide {
      display: none;
    }
  }

  &[open] {
    .c-card__action--show {
      display: none;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    outline: 0;

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__icon {
    width: 1.5 * $size-base;
    flex: 0 0 auto;
    margin-right: 0.75 * $size-base;
    margin-top: -3px;
    color: var(--color-primary);
    font-size: 1.25 * $size-base;

    &--image {
      --size: #{2.5 * $size-base};
      width: var(--size);
      height: var(--size);
      overflow: hidden;
      border-radius: $size-md;

      img {
        min-width: 100%;
        min-height: 100%;
        width: auto;
      }
    }

    &--lg {
      font-size: 2 * $size-base;
    }
  }

  &__numeric {
    flex: 0 0 auto;
    margin-right: 0.75 * $size-base;
    margin-bottom: -3px;
    color: var(--color-secondary-700);
    font-size: 1.25 * $size-base;
  }

  &__title {
    display: flex;
    align-items: center;
    min-width: 0;
    flex: 1 1 auto;
    font-weight: 600;
  }

  &__action {
    --button-background: var(--color-white);

    &--offset {
      margin-top: -$size-sm;
      margin-right: -$size-sm;
    }

    &--passthrough {
      pointer-events: none;
    }

    &--hide {
      color: var(--color-gray-500);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: $size-base;
    overflow: hidden;

    img {
      min-width: 100%;
      min-height: 100%;
      width: auto;
    }
  }

  &__body {
    display: flex;
    align-items: flex-end;
    margin-top: $size-md;

    &--white {
      margin: #{-0.75 * $size-base};
      margin-top: $size-md;
      padding: $size-md;
      border-radius: $size-base;
      background: var(--color-white);
    }

    &--vertical {
      align-items: stretch;
      flex-direction: column;
    }
  }

  &__info {
    display: flex;
    align-items: flex-end;
    flex: 1 1 auto;
  }

  &__subinfo {
    color: var(--color-gray-500);
    font-size: 0.85 * $size-base;
  }

  &__illustration {
    margin-top: -$size-sm;
    margin-right: -$size-sm;
    margin-left: $size-md;
  }
}
