@import '../sizing.scss';
@import '../variables.scss';

.c-link {
  --color: var(--color-primary);
  --font-weight: 600;
  --hover-color: var(--color-secondary-700);
  --text-decoration: none;

  display: inline-flex;
  align-items: center;
  color: var(--color-primary);
  font-weight: var(--font-weight);
  text-decoration: var(--text-decoration);
  cursor: pointer;

  &--subtle {
    font-weight: normal;
    text-decoration: underline;
    text-align: left;
    white-space: nowrap;
  }

  ion-icon {
    margin-left: $size-xs;
  }

  &:hover {
    color: var(--hover-color);
  }

  &--external {
    font-weight: 400;

    &:after {
      content: '';
      width: 18px;
      height: 18px;
      margin-left: $size-xs;
      margin-top: -5px;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='512px' height='512px' viewBox='0 0 512 512' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath fill='%23025450' d='M405.34,405.332H106.66V106.668H240V64H106.66C83.191,64,64,83.197,64,106.668v298.664C64,428.803,83.191,448,106.66,448 h298.68c23.469,0,42.66-19.197,42.66-42.668V272h-42.66V405.332z M288,64v42.668h87.474L159.999,322.133l29.866,29.866 l215.476-215.47V224H448V64H288z'/%3E%3C/svg%3E");
      background-size: contain;
    }
  }

  &--dynamic {
    --color: unset;
  }
}
