@import 'sizing.scss';
@import 'variables.scss';

// POSITION
.u-position--relative {
  position: relative !important;
}

// DISPLAY
.u-display--block {
  display: block !important;
}

.u-display--inline {
  display: inline !important;
}

.u-display--none {
  display: none !important;
}

.u-display--flex {
  display: flex !important;
}

.u-flex--auto {
  flex: auto !important;
}

.u-flex-grow--1 {
  flex-grow: 1;
}

.u-flex-direction--row {
  flex-direction: row;
}

.u-flex-direction--column {
  flex-direction: column;
}

.u-flex-align--center {
  align-items: center !important;
}

.u-flex-align--start {
  align-items: flex-start !important;
}

.u-flex-align--end {
  align-items: flex-end !important;
}

.u-flex-justify--center {
  justify-content: center;
}

.u-flex-justify--start {
  justify-content: flex-start;
}

.u-flex-align-self--start {
  align-self: flex-start;
}

.u-flex-justify--end {
  justify-content: flex-end;
}

.u-flex-justify--space-between {
  justify-content: space-between;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

// FONT
.u-text-align--center {
  text-align: center !important;
}

.u-text-align--left {
  text-align: left !important;
}

.u-text-align--right {
  text-align: right !important;
}

.u-text-align--justify {
  text-align: justify !important;
}

.u-font-weight--regular {
  font-weight: 400 !important;
}

.u-font-weight--semibold {
  font-weight: 600 !important;
}

.u-font-weight--bold {
  font-weight: 700 !important;
}

.u-font-style--italic {
  font-style: italic;
}

.u-text-decoration--underline {
  text-decoration: underline;
}

.u-text-decoration--line-through {
  text-decoration: line-through;
}

.u-text-decoration--none {
  text-decoration: none;
}

.u-text-transform--uppercase {
  text-transform: uppercase !important;
}

.u-text-transform--lowercase {
  text-transform: lowercase !important;
}

.u-text-transform--capitalize {
  text-transform: capitalize !important;
}

.u-text-transform--none {
  text-transform: none !important;
}

.u-text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.u-text-wrap {
  white-space: pre-line !important;
}

.u-text-single-line {
  white-space: nowrap !important;
}

.u-text-base {
  font-size: $size-base;
}

.u-text-label {
  font-size: 0.9 * $size-base !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.u-line-height--single {
  line-height: 1em !important;
}

// WIDTH
.u-width--7rem {
  width: 7.5rem !important;
}

.u-width--10 {
  width: 10% !important;
}

.u-width--25 {
  width: 25% !important;
}

.u-width--33 {
  width: 33% !important;
}

.u-width--50 {
  width: 50% !important;
}

.u-width--66 {
  width: 66% !important;
}

.u-width--75 {
  width: 75% !important;
}

.u-width--90 {
  width: 90% !important;
}

.u-width--100 {
  width: 100% !important;
}

// MAX WIDTH
.u-max-width--20rem {
  max-width: 20rem !important;
}

.u-max-width--24rem {
  max-width: 24rem !important;
}

.u-max-width--30rem {
  max-width: 30rem !important;
}

.u-max-width--32rem {
  max-width: 30rem !important;
}

.u-max-width--36rem {
  max-width: 36rem !important;
}

.u-max-width--10 {
  max-width: 10% !important;
}

.u-max-width--25 {
  max-width: 25% !important;
}

.u-max-width--33 {
  max-width: 33% !important;
}

.u-max-width--50 {
  max-width: 50% !important;
}

.u-max-width--66 {
  max-width: 66% !important;
}

.u-max-width--75 {
  max-width: 75% !important;
}

.u-max-width--90 {
  max-width: 90% !important;
}

.u-max-width--100 {
  max-width: 100% !important;
}

// HEIGHT
.u-height--100 {
  height: 100% !important;
}

.u-height--0 {
  height: 0 !important;
}

// CURSOR
.u-cursor--clickable {
  cursor: pointer;
}

.u-pointer-events--none {
  pointer-events: none;
}

// MARGIN AND PADDING
$sizes: (
  'xs': $size-xs,
  'sm': $size-sm,
  'md': $size-md,
  'lg': $size-lg,
  'xl': $size-xl,
  'page': $size-page,
);
$directions: 'left', 'right', 'top', 'bottom';

@each $direction in $directions {
  @each $size, $size-value in $sizes {
    .u-margin-#{$direction}--#{$size} {
      margin-#{$direction}: $size-value !important;
    }

    .u-margin-#{$direction}--neg-#{$size} {
      margin-#{$direction}: -$size-value !important;
    }

    .u-padding-#{$direction}--#{$size} {
      padding-#{$direction}: $size-value !important;
    }

    .u-padding-#{$direction}--neg-#{$size} {
      padding-#{$direction}: -$size-value !important;
    }
  }
}

@each $size, $size-value in $sizes {
  .u-margin--#{$size} {
    margin: $size-value !important;
  }

  .u-padding--#{$size} {
    padding: $size-value !important;
  }
}

@each $direction in $directions {
  .u-margin-#{$direction}--auto {
    margin-#{$direction}: auto !important;
  }

  .u-margin-#{$direction}--none {
    margin-#{$direction}: 0 !important;
  }

  .u-padding-#{$direction}--none {
    padding-#{$direction}: 0 !important;
  }
}

// RESPONSIVENESS
$breaks: (
  'xs': $break-xs,
  'sm': $break-sm,
  'md': $break-md,
  'md2': $break-md2,
  'lg': $break-lg,
  'xl': $break-xl,
);

.u-hide {
  display: none !important;
}

@each $break, $break-value in $breaks {
  @media screen and (max-width: #{$break-value - 1px}) {
    .u-hide--#{$break} {
      display: none !important;
    }
  }

  @media screen and (min-width: #{$break-value}) {
    .u-show--#{$break} {
      display: none !important;
    }
  }

  .u-ashow--#{$break} {
    display: none !important;
  }

  @media screen and (min-width: #{$break-value}) {
    .u-ashow--#{$break} {
      display: block !important;
    }
  }
}

// COLORS AND BACKGROUND
$colors: (
  'primary': var(--color-primary),
  'primary-100': var(--color-primary-100),
  'secondary': var(--color-secondary-500),
  'secondary-500': var(--color-secondary-500),
  'secondary-700': var(--color-secondary-700),
  'secondary-300': var(--color-secondary-300),
  'secondary-100': var(--color-secondary-100),
  'white': var(--color-white),
  'black': var(--color-black),
  'green': var(--color-green),
  'yellow': var(--color-yellow),
  'red': var(--color-red),
  'secondary-red': var(--color-secondary-red),
  'orange': var(--color-orange),
  'gray-100': var(--color-gray-100),
  'gray-200': var(--color-gray-200),
  'gray-300': var(--color-gray-300),
  'gray-400': var(--color-gray-400),
  'gray-500': var(--color-gray-500),
  'gray-600': var(--color-gray-600),
  'gray-700': var(--color-gray-700),
);

@each $color, $color-value in $colors {
  .u-color--#{$color} {
    color: $color-value !important;
  }

  .u-background--#{$color} {
    background-color: $color-value !important;
  }
}

.u-desktop-decoration {
  --background: none;
  background: white;
  background-image: url('/assets/images/desktop-decoration-small.jpg');
  background-repeat: no-repeat;
  background-size: auto 100%;

  @media screen and (max-width: $break-md) {
    --background: white;
    background-image: none;
  }

  @media screen and (min-width: $break-md) {
    --background: none;
    background-image: url('/assets/images/desktop-decoration-small.jpg');
    background-position: 100% center;
  }

  @media screen and (min-width: $break-md2) {
    background-image: url('/assets/images/desktop-decoration.jpg');
  }

  @media screen and (min-width: $break-md2) and (max-width: $break-lg) {
    background-position: calc(#{$max-sm} + 80px) center;
  }

  @media screen and (min-width: $break-lg) {
    background-position: right center;
  }
}
