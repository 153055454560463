.c-fab {
  --background: var(--color-primary);
  --background-activated: var(--color-black);
  --color: var(--color-white);
  --color-activated: var(--color-white);
  --border-color: transparent;

  &--primary {
    &.fab-button-close-active {
      --background: var(--color-black);
    }
  }

  &--secondary {
    --background: var(--color-white);
    --background-activated: var(--color-white);

    --color: var(--color-primary);
    --color-activated: var(--color-primary);
  }
}
