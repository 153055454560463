@import '../sizing.scss';

.c-icon {
  --color: var(--color-black);
  --size: #{3 * $size-base};
  --font-size: #{1.5 * $size-base};

  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  background: url('/assets/images/imperfect-circle.svg');
  background-repeat: no-repeat;
  background-size: contain;
  color: var(--color);
  font-size: var(--font-size);

  &--xl {
    --size: #{7 * $size-base};
    --font-size: #{4 * $size-base};
  }

  &--lg {
    --size: #{5 * $size-base};
    --font-size: #{2.5 * $size-base};
  }

  &--md {
    --size: #{4 * $size-base};
    --font-size: #{2 * $size-base};
  }

  &--sm {
    --size: #{2.5 * $size-base};
    --font-size: #{1.5 * $size-base};
  }

  &--xs {
    --size: #{1.6 * $size-base};
    --font-size: #{1.25 * $size-base};
  }

  &--xxs {
    --size: #{1.25 * $size-base};
    --font-size: #{0.75 * $size-base};
  }

  &--black {
    ion-icon,
    img {
      filter: brightness(0);
    }
  }

  &--red {
    background-image: url('/assets/images/imperfect-circle-red.svg');
  }

  &--green {
    ion-icon {
      color: var(--color-secondary-700);
    }
  }

  &--loading {
    filter: grayscale(1);
  }

  &--bare {
    background: none !important;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &--custom {
    background-image: unset;
    mask-image: url('/assets/images/imperfect-circle.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('/assets/images/imperfect-circle.svg');
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;

    img {
      max-height: 2.5 * $size-base !important;
    }
  }

  @media screen and (max-width: $break-xs) {
    &--xl {
      --size: #{5.5 * $size-base};
      --font-size: #{3.5 * $size-base};
    }
  }

  img {
    width: 100%;
    max-width: calc(0.95 * var(--font-size));
    max-height: calc(0.95 * var(--font-size));
  }
}
