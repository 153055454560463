// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// new imports
@import 'src/theme/app.scss';
@import 'src/theme/base.scss';
@import 'src/theme/sizing.scss';
@import 'src/theme/layout.scss';
@import 'src/theme/fonts.scss';
@import 'src/theme/utilities.scss';
@import 'src/theme/components.scss';
@import 'src/theme/ionic.scss';
@import 'src/theme/cookies.scss';
