@import 'sizing.scss';

html,
body {
  font-size: 17px;
  line-height: 1.4em;
}

* {
  box-sizing: border-box;
}

.c-success {
  .loading-content {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: inline-flex;
      width: 1.5 * $size-base;
      height: 1.5 * $size-base;
      margin-right: $size-sm;
      border-radius: 50%;
      background: var(--color-green);
      background-image: url('/assets/images/checkmark-simple.svg');
      background-position: 55% 50%;
      background-repeat: no-repeat;
    }
  }
}
