.c-tooltip {
  display: inline-block;
  position: relative;

  --text-visibility: hidden;
  --text-left: 50%;
  --text-right: auto;
  --text-translate-x: -50%;

  &__text {
    visibility: var(--text-visibility);
    position: absolute;
    top: -2.5rem;
    left: var(--text-left);
    right: var(--text-right);
    transform: translateX(var(--text-translate-x));
    padding: $size-sm $size-md;
    background-color: var(--color-black);
    color: white;
    font-size: 14px;
    font-weight: 600;
    text-align: center;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: var(--color-black) transparent transparent transparent;
    }
  }

  &:hover {
    --text-visibility: visible;
  }

  &--left {
    --text-left: 0;
    --text-translate-x: 0%;
  }

  &--right {
    --text-left: auto;
    --text-right: 0;
    --text-translate-x: 0%;
  }
}
