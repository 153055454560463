@import '../sizing.scss';
@import '../variables.scss';

.c-wizard {
  align-items: center;
  max-width: $max-xs;
  min-height: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: max(
    #{$size-page},
    calc(#{$size-page} + constant(safe-area-inset-bottom))
  ); // for ios 11.1
  padding-bottom: max(
    #{$size-page},
    calc(#{$size-page} + env(safe-area-inset-bottom))
  ); // for ios 11.2 onwards

  &__wrapper {
    display: contents;
  }

  &__icon {
    margin-top: $size-md;
  }

  &__title {
    margin-top: $size-lg;
  }

  &__description {
    margin-top: $size-md;
  }

  &__note {
    width: 18 * $size-base;
    margin-top: auto;
    margin-bottom: $size-lg;
    color: var(--color-gray-700);

    ~ .c-wizard__footer {
      margin-top: 0;
    }
  }

  &__footer {
    width: 100%;
    margin-top: auto;

    app-button {
      width: 100%;
    }
  }
}

@media screen and (max-width: $break-xs) {
  .c-wizard {
    &__icon {
      margin: 0;
    }

    &__description {
      margin-top: $size-sm;
    }
  }
}

@media screen and (min-width: $break-md) {
  .c-wizard {
    position: relative;
    z-index: 2;
    flex: 1 0 auto;
    max-width: none;
    margin: 0;
    align-items: flex-start;
    padding: (3 * $size-base);
    padding-top: $size-lg;
    text-align: left;

    &--bare {
      min-height: unset;
    }

    &__icon {
      display: none !important;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      width: 100%;
      padding: 0;
    }

    &__title {
      margin-top: 0;
      font-size: 2.3 * $size-base;
      font-weight: 900;
    }

    &__description {
      font-size: 1.2 * $size-base;
    }

    &__note {
      width: 24 * $size-base;
      font-size: 1.15 * $size-base;

      &--lg {
        width: 36 * $size-base;
      }

      &--auto {
        width: auto;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      padding-top: $size-md;

      p {
        font-size: 1.1 * $size-base;
      }

      app-button {
        width: auto;
        min-width: 200px;
      }
    }
  }
}

@media screen and (min-width: $break-md2) {
  .c-wizard {
    max-width: $max-md;

    &__scroll-container {
      @media screen and (min-height: $max-md) {
        padding-right: 3rem;
        height: 38 * $size-base;
        overflow-y: auto;
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;

        &-thumb {
          background: transparent;
          border-radius: 15px;

          &:hover {
            background: #bdbdbd;
          }
        }

        &-track {
          background: transparent;
          border-radius: 15px;
        }
      }
      &:hover {
        &::-webkit-scrollbar {
          &-thumb {
            background: #ccc;
          }
        }
      }
    }
  }
}
