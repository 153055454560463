@import '../sizing.scss';
@import '../variables.scss';

.c-section {
  &--sm {
    .c-section__title {
      margin-bottom: 0;
      font-size: 0.85 * $size-base;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &--lg {
    .c-section__header {
      border-bottom: 1px solid var(--color-gray-300);
      padding-bottom: $size-md;
      margin-bottom: $size-md;
    }

    .c-section__title {
      font-size: 1.5 * $size-base;
    }
  }

  &--full {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $size-md;

    &--bare {
      border: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0;
    color: var(--color-black);
    font-size: 1.5 * $size-base;
    font-weight: 600;
  }

  &__heading {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: $size-sm;
    color: var(--color-black);
    font-size: 1.25 * $size-base;
    font-weight: 400;
  }

  &__m-title {
    display: none;
    margin: 0;
    color: var(--color-black);
    font-size: 1.1 * $size-base;
    font-weight: 600;

    @media screen and (max-width: $break-sm) {
      display: block;
    }
  }

  &__text {
    margin-top: $size-sm;
    margin-bottom: $size-md;
    color: var(--color-gray-500);
    font-size: 0.95 * $size-base;
  }

  &__actions {
    margin-left: auto;
  }
}
