@import '../sizing.scss';
@import '../variables.scss';

.c-btn {
  --button-background: var(--color-primary);
  --button-color: white;
  --button-border-color: transparent;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 100%;
  max-width: 100%;
  height: 3.15 * $size-base;
  padding: $size-base 3 * $size-base;
  border-radius: $size-base;
  background: var(--button-background);
  border: 2px solid var(--button-border-color);
  appearance: none;

  color: var(--button-color);
  font-size: $size-base;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  outline: none;

  cursor: pointer;

  &:hover,
  &:active {
    --button-background: var(--color-secondary-700);
    --button-color: var(--color-white);
  }

  &:active {
    opacity: 0.9;
  }

  &:disabled {
    --button-background: var(--color-gray-200);
    --button-color: var(--color-gray-400);

    pointer-events: none;
  }

  &--secondary {
    --button-background: transparent;
    --button-color: var(--color-primary);
    --button-border-color: var(--color-primary);

    &:disabled {
      --button-border-color: var(--color-gray-300);
      --button-background: transparent !important;
    }

    &:hover,
    &:active {
      --button-background: transparent;
      --button-color: var(--color-secondary-700);
      --button-border-color: var(--color-secondary-700);
    }
  }

  &--terciary {
    --button-background: var(--color-gray-100);
    --button-color: var(--color-black);
    --button-border-color: transparent;

    &:disabled {
      --button-border-color: var(--color-gray-300);
      --button-background: transparent !important;
    }

    &:hover,
    &:active {
      --button-background: transparent;
      --button-color: var(--color-secondary-700);
      --button-border-color: var(--color-secondary-700);
    }
  }

  &--red {
    --button-background: var(--color-red);
  }

  &--full {
    width: 100%;
    padding: $size-md;
  }

  &--sm {
    min-width: 0;
    height: 1.5 * $size-base;
    padding: $size-sm 0.75 * $size-base;
    font-size: 0.75 * $size-base;
    font-weight: 700;
    text-transform: uppercase;

    &.c-btn--secondary {
      --button-background: var(--color-gray-200);
      --button-color: var(--color-primary);
      --button-border-color: transparent;

      &:hover,
      &:active {
        --button-background: var(--color-primary);
        --button-color: var(--color-white);
      }
    }

    .c-btn__arrow,
    .c-btn__arrow--back {
      position: static;
      margin-right: -$size-sm;
      margin-top: -1px;
      font-size: 1rem;
    }

    &:hover,
    &:active {
      --button-background: var(--color-secondary-700);
      --button-color: var(--color-white);
    }
  }

  &--icon {
    min-width: 0;
  }

  &--icon-only {
    padding: $size-md #{0.75 * $size-base};
  }

  &--arrow {
    --button-background: var(--color-gray-200);
    --button-color: var(--color-primary);

    min-width: 0;
    width: 1.85 * $size-base;
    height: 1.85 * $size-base;
    padding: $size-xs;
    border-radius: 50%;

    .c-btn__icon {
      font-size: 1.2rem;
    }
  }

  &--white {
    --button-background: var(--color-white) !important;
    --button-color: var(--color-primary) !important;
  }

  &--responsive {
    @media screen and (min-width: $break-sm) {
      font-size: 1.1 * $size-base;
      padding: 1.75 * $size-base 3 * $size-base;
    }
  }

  &__loading {
    position: absolute;
    width: $size-lg;
    height: $size-lg;
    margin-bottom: -$size-sm;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__icon {
    font-size: 1.5rem;
  }

  &__external {
    position: absolute;
    font-size: 1.5rem;
    right: $size-base;
    top: 50%;
    transform: translateY(-50%);
  }

  &__arrow {
    position: absolute;
    right: 0.75 * $size-base;
    font-size: 1.5rem;

    &--back {
      position: absolute;
      left: 0.75 * $size-base;
      font-size: 1.5rem;
    }
  }

  &__content {
    margin-bottom: -2px;

    // the following CSS only applies to Safari browsers
    // which render Overpass font a bit differently,
    // therefore the alingment hack is not needed
    @supports (-webkit-hyphens: none) {
      margin-bottom: 0;
    }
  }

  &__spinner {
    color: var(--color-gray-500);
  }
}
