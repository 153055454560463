@import '../sizing.scss';
@import '../variables.scss';

@media screen and (min-width: $break-md) {
  // Force to show header if running on native (eg.: tablet)
  .c-header:not(.c-header--force-mobile) {
    display: none;
  }
}

.header-translucent-ios {
  backdrop-filter: saturate(180%) blur(10px);
}

.l-container {
  .c-header--responsive {
    .c-header__wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.l-desktop {
  .c-header {
    &__wrapper {
      max-width: none;
      padding: $size-lg (3 * $size-base);
      padding-bottom: 0;
    }
  }
}

.c-header {
  --ion-toolbar-color: var(--color-black);

  opacity: 1;
  transition: opacity ease-in-out 0.3s;

  &--hidden {
    opacity: 0;
  }

  &--transition {
    opacity: 0;
  }

  &--clear {
    backdrop-filter: none;

    ion-toolbar {
      --border-width: 0 !important;
      background: transparent;
      --background: transparent;
    }
  }

  &--responsive {
    display: none;
    align-items: center;
    transition: background ease-in-out 0.4s;

    @media screen and (min-width: $break-md) {
      // Force to show normal header if running on native (eg.: tablet)
      &:not(.c-header--force-mobile) {
        display: flex;
      }
    }
  }

  &--translucent {
    backdrop-filter: saturate(180%) blur(10px);
    background: rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid var(--color-gray-300);
  }

  &--inline {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: -$size-md;
    margin-bottom: $size-md;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: $size-md $size-page;
    max-width: $max-lg;
    margin: 0 auto;
  }

  &__icon {
    color: var(--color-black);
    font-size: 1.5 * $size-base;

    &:hover,
    &:focus {
      color: var(--color-primary);
    }
  }

  &__logo {
    display: block;
    width: 240px;
    height: 26px;

    background: url('/assets/images/logo.svg');
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;

    cursor: pointer;
  }

  &__navigation {
    display: flex;
    align-items: center;
    margin-left: auto;
    list-style: none;

    &-item {
      margin-left: $size-lg;
    }

    &-link {
      display: flex;
      align-items: center;
      color: var(--color-black);
      font-weight: 700;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
      }

      ion-icon {
        display: block;
        margin-top: -3px;
        font-size: 1.25 * $size-base;
      }

      ion-select {
        padding: 0 $size-sm;

        & + ion-icon {
          margin-top: -5px;
          margin-left: -$size-md;
          z-index: 2;
        }
      }
    }

    &-language {
      --text-color: var(--color-black);
      --highlight-color: var(--color-primary);

      &-icon {
        font-size: 1.5 * $size-base;
      }
    }
  }

  ion-title {
    font-weight: 700;
  }
}
