@import '../sizing.scss';
@import '../variables.scss';

.c-logo {
  display: flex;
  align-items: center;
  margin: 0;

  img {
    height: 1.3 * $size-base;
  }

  &--lg {
    img {
      height: 1.8 * $size-base;
    }
  }

  &--sm {
    img {
      height: 1.2 * $size-base;
    }
  }
}
