@import '../sizing.scss';

.c-skeleton {
  margin: 0;
  margin-bottom: $size-md;
  height: $size-md;

  --background: var(--color-gray-200);

  &__row {
    display: flex;

    .c-skeleton__divider {
      margin: 0 $size-base;
      height: $size-md;
      width: 1px;
      background-color: var(--color-gray-500);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--full {
    width: 100%;
  }

  &--3quarter {
    width: 75%;
  }

  &--half {
    width: 50%;
  }

  &--quarter {
    width: 25%;
  }

  &--eighth {
    width: 12.5%;
  }

  &--tiny {
    width: 10%;
  }

  &--icon {
    width: 3rem;
  }

  &--lg {
    height: 1.5 * $size-base;
  }

  &--image {
    height: 100%;
  }

  &--darker {
    --background: var(--color-gray-300);
  }

  &--md {
    height: $size-md;
    margin-bottom: $size-sm;
  }

  &--sm {
    height: 0.75 * $size-base;
    margin-bottom: $size-xs;
  }

  &--6rem {
    width: 6rem;
  }

  &--4rem {
    width: 4rem;
  }

  &--2rem {
    width: 2rem;
  }

  &--1rem {
    width: 1rem;
  }
}
