@import 'sizing.scss';

.l-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $size-page;

  &--full {
    min-height: 100%;
  }
}

.l-grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 2rem);
  margin: 0 -1rem;

  &--wrap {
    flex-wrap: wrap;
  }

  &--fill {
    > .l-grid__item {
      flex: 1 0 auto;
    }
  }

  &--even {
    > .l-grid__item {
      justify-content: space-between;

      > * {
        flex: 0;
      }
    }
  }

  &--2 {
    > .l-grid__item {
      width: 50%;

      @media screen and (max-width: $break-sm) {
        width: 100% !important;
        padding-top: 0;
      }
    }
  }

  &--3 {
    > .l-grid__item {
      width: 33.3%;

      @media screen and (max-width: $break-sm) {
        width: 100% !important;
        padding-top: 0;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: $size-md;
    flex: 1 0 auto;

    &--3 {
      width: 33.3% !important;
    }

    > * {
      flex: 1 0 auto;
    }
  }
}

.l-desktop {
  background: var(--color-gray-100);

  &__container {
    height: 100%;

    @media screen and (min-width: $break-md2) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      max-width: $max-xl;
      max-height: $max-md;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.12);
    }

    @media screen and (min-width: $break-xl) {
      border-radius: 12px;
    }

    @media screen and (min-width: $break-md) {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    @media screen and (max-height: $max-md) and (min-width: $break-md) {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.l-footer {
  margin-top: auto;
}
