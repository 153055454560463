@import '../sizing.scss';
@import '../variables.scss';

.c-field {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  --inner-border-width: 0;
  --border-width: 0;
  --background: transparent;
  --background-activated: transparent;
  --background-hover: transparent;
  --background-focused: transparent;

  --highlight-height: 0px;

  --label-color: var(--color-gray-500);
  --icon-color: var(--color-secondary-700);
  --border-color: var(--color-gray-300);
  --input-background: none;

  position: relative;
  caret-color: var(--label-color) !important;

  // TODO: Improve this style, this was added quickly due to an urgent task
  &--light {
    --label-color: var(--color-gray-600);
    color: var(--color-gray-600);
    font-size: 16px;
  }

  &.item-label-floating {
    margin-top: -$size-lg; // account for label reserved space
  }

  &.item-label-stacked {
    margin-top: -$size-sm; // account for label reserved space
  }

  &.item-has-value {
    --label-color: var(--color-black);
  }

  &.item-has-focus {
    --icon-color: var(--color-primary);
    --border-color: var(--color-primary);
    --label-color: var(--color-primary);
    caret-color: var(--color-primary);
  }

  &.item-interactive-disabled {
    --color: var(--color-gray-700);
    --icon-color: var(--color-gray-400);
    --input-background: var(--color-gray-100);
  }

  .item-label-floating.item-has-placeholder:not(.item-has-value) {
    opacity: 1;
  }

  &.item-has-focus,
  &.item-has-value {
    .label-floating {
      top: 0.8 * $size-base;
      left: $size-md;
      background-color: var(--color-white);
      pointer-events: none;
    }
  }

  &.ion-invalid.ion-touched {
    --icon-color: var(--color-red);
    --border-color: var(--color-red);
    --label-color: var(--color-black);

    &.item-has-focus {
      --label-color: var(--color-red);
    }
  }

  &__icon {
    position: absolute;
    z-index: 5;
    left: $size-md;
    bottom: 0.375 * $size-base;
    color: var(--icon-color);
    font-size: 1.5 * $size-base;

    ~ .label-floating {
      left: 3.2 * $size-base;
    }

    &--floating {
      bottom: $size-sm !important;
    }

    ~ .c-field__picker .c-field__input,
    ~ .c-field__input {
      --padding-start: #{3.2 * $size-base} !important;

      .native-input {
        padding-left: 3.2 * $size-base;
      }
    }

    &--image {
      max-width: 1.5 * $size-base;
      bottom: unset;
    }
  }

  &__action {
    position: absolute;
    z-index: 5;
    right: $size-md;
    bottom: $size-base;
    color: var(--color-gray-500);
    font-size: 1.3 * $size-base;

    ~ .c-field__input {
      --padding-end: 0;

      .native-input {
        padding-right: 3.2 * $size-base;
      }
    }
  }

  &__label {
    left: $size-md;

    &.label-floating {
      position: relative;
      top: 0.625 * $size-base;
      z-index: 4;
      width: fit-content !important;
      padding: 0 $size-sm;
      margin-left: -$size-sm;
      color: var(--label-color) !important;
      transition-property: transform, left, background-color !important;
    }

    &.label-stacked {
      margin-bottom: $size-sm;
      font-size: $size-base;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  &__input {
    --padding: #{0.85 * $size-base};
    --padding-top: var(--padding) !important;
    --padding-bottom: var(--padding) !important;
    --padding-start: #{$size-md} !important;
    --highlight-height: 0px;
    caret-color: var(--label-color) !important;

    opacity: 1 !important;

    min-height: 3.25 * $size-base;
    border: 1px solid var(--border-color);
    border-radius: 0.75 * $size-base;
    background: var(--input-background);
    overflow: hidden;

    .input-clear-icon {
      color: var(--icon-color);
    }
  }

  &__error {
    margin-top: $size-xs;
    color: var(--color-red);
    font-size: 0.85 * $size-base;
    font-weight: 600;
  }

  &__success {
    margin-top: $size-xs;
    color: var(--color-green);
    font-size: 0.85 * $size-base;
    font-weight: 600;
  }

  &__toggle {
    display: flex;
    width: 100%;
    min-height: 3.25 * $size-base;
    background: var(--color-gray-100);
    border-radius: 0.75 * $size-base;
    border: 1px solid var(--color-gray-200);

    &--sm {
      min-height: 2.75 * $size-base;

      .c-field__option {
        &-label {
          font-size: 0.85 * $size-base !important;
        }
      }
    }

    .c-field__option {
      flex: 1 0;
      margin: 3px;

      &-radio {
        display: none;

        &:disabled + .c-field__option-label {
          opacity: 0.5;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: $size-sm (0.75 * $size-base);
        border-radius: 0.75 * $size-base;
        border: 2px solid transparent;
        background: transparent;
        color: var(--color-black);
        font-size: $size-base;
        text-align: center;
        cursor: pointer;
      }

      &:hover {
        color: var(--color-black);
      }

      &--checked,
      &.item-radio-checked,
      &-radio:checked + .c-field__option-label {
        background: var(--color-primary-100);
        border-color: var(--color-primary);
        font-weight: 700;
      }
    }

    @media screen and (max-width: $break-xs) {
      .c-field__option {
        &-label {
          padding: $size-sm;
          font-size: 0.75 * $size-base !important;
        }
      }
    }
  }

  &__checkbox {
    margin-right: $size-md;
    --background-checked: var(--color-primary);
    --border-color-checked: var(--color-primary);
    margin-right: $size-sm;
  }

  &__radio {
    --background: var(--color-gray-100);
    --border-color: transparent;
    --border-radius: 0.75rem;

    min-height: 3.25 * $size-base;
    margin-bottom: $size-sm;
    border-radius: 0.75 * $size-base;
    border: 2px solid transparent;
    overflow: hidden;

    &--checked,
    &.item-radio-checked,
    &.item-checkbox-checked {
      --background: var(--color-primary-100);
      border-color: var(--color-primary);
    }

    &-label {
      --margin: #{0.85 * $size-base};
      margin-top: var(--margin);
      margin-bottom: var(--margin);
      font-size: $size-md !important;
    }

    &-input {
      --color: var(--color-black);
      --color-checked: var(--color-primary);
      margin-right: $size-md;
    }
  }

  &__date {
    width: 100vw;
    outline: 0;
    z-index: 10;
    padding: $size-base $size-sm 0 $size-base;
    margin-bottom: max(#{$size-xs}, constant(safe-area-inset-bottom)); // for ios 11.1
    margin-bottom: max(#{$size-xs}, env(safe-area-inset-bottom));
    --ion-color-base: var(--color-primary) !important;
  }

  &__picker {
    width: 100%;

    .c-field__input {
      pointer-events: none;
    }

    &-inner {
      &.picker-md .picker-opt.picker-opt-selected {
        color: var(--color-primary);
      }

      &.picker-opt.picker-opt-selected {
        color: var(--color-primary);
      }
    }
  }

  &__code-input {
    max-width: 2.75 * $size-base;
    height: 3.25 * $size-base;
    --pading: 0.75 * $size-base;
    --padding-start: 12px !important;
    --padding-end: 12px !important;

    border: 1px solid var(--border-color);
    border-radius: 0.75 * $size-base;
    background: var(--input-background);
    overflow: hidden;
    font-weight: bold;
    font-size: 1.5 * $size-base;
    text-align: center;

    // hide numeric arrows
    ::ng-deep input::-webkit-outer-spin-button,
    ::ng-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:not(:last-of-type) {
      margin-right: 0.75 * $size-base;
    }
  }
}

.c-accept-terms {
  margin-left: 5px;
  color: #717171;
  --color: #717171;

  font-size: 16px;

  &__line {
    display: flex;
    align-items: center;

    &--first {
      height: 28px;
    }
  }

  &__checkbox {
    --label-color: #717171;
    color: red;
  }

  &__label {
    margin-right: 4px;
    text-align: left;
    max-width: 380px;
    a {
      word-wrap: break-word;
      white-space: normal;
      display: inline;
    }
  }
}
