@import './sizing.scss';
@import './variables.scss';

.cc-window {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;

  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: none !important;
  background-color: rgba(30, 27, 44, 0.2) !important;
  overflow: auto !important;

  font-family: inherit !important;
  padding: 8px !important;

  &:not(.cc-invisible) + app-root {
    display: block;
    pointer-events: none;
    overflow: hidden;
    height: 100vh;
  }

  &.cc-floating.cc-theme-edgeless {
    .cc-message {
      margin: 0;
      margin-top: auto;
      padding: 3 * $size-base;
      padding-bottom: 2 * $size-base;
      max-width: 38 * $size-base;
      background: var(--color-white);
      border-radius: $size-base $size-base 0 0;
      font-size: 18px;

      h1 {
        margin-bottom: $size-base;
        text-align: center;

        @media screen and (max-width: $break-md2) {
          font-size: 22px;
          line-height: 1.36;
        }
      }

      a {
        color: var(--color-primary);
      }

      @media screen and (max-width: $break-md2) {
        padding-left: $size-base;
        padding-right: $size-base;
        overflow: auto;
        max-height: 70vh;
      }
    }

    .cc-compliance {
      flex-direction: column-reverse;
      width: 100%;
      max-width: 38 * $size-base;
      flex: none;
      padding: 3 * $size-base;
      padding-top: 0;
      margin-bottom: auto;
      background: var(--color-white);
      border-radius: 0 0 $size-base $size-base;

      @media screen and (max-width: $break-md2) {
        flex-direction: column;
        padding-bottom: $size-base;
      }

      .cc-btn {
        outline: 0;
        border-radius: $size-base;
        min-width: 18 * $size-base;
        padding: $size-base 0;
        font-size: $size-base;
        text-transform: uppercase;
      }

      .cc-allow {
        border: 0;
        background: var(--color-primary);
        color: var(--color-white);

        &:hover,
        &:active {
          background: var(--color-secondary-700);
        }
      }

      .cc-deny {
        margin-top: 0.5 * $size-base;
        outline: 0;
        border: 0;
        border-radius: $size-base;
        // padding: $size-base 6 * $size-base;
        padding-bottom: 0;
        font-size: $size-base;
        color: var(--color-primary);
        text-decoration: none;

        &:hover,
        &:active {
          color: var(--color-secondary-700);
        }

        @media screen and (max-width: $break-md2) {
          margin: 0;
          margin-bottom: 1.5 * $size-base;
        }
      }
    }
  }
}

.cc-revoke {
  display: none !important;
}
