.c-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: $size-sm;
  border-left: 2px solid var(--color-gray-300);

  &__item {
    outline: 0;
    position: relative;
    padding: $size-sm;
    margin: $size-sm 0;
    padding-left: $size-lg;
    color: var(--color-black);
    font-size: 0.95 * $size-base;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    &--selected {
      color: var(--color-primary);
      font-weight: 600;

      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: 0;
        bottom: 0;
        width: 2px;
        background: var(--color-primary);
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
