$size-base: 1rem;
$size-xs: $size-base * 0.25;
$size-sm: $size-base * 0.5;
$size-md: $size-base * 1;
$size-lg: $size-base * 2;
$size-xl: $size-base * 4;
$size-page: $size-base * 1.25;

$break-xs: 375px;
$break-sm: 640px;
$break-md: 768px;
$break-md2: 1024px;
$break-lg: 1200px;
$break-xl: 1366px;

$max-xs: 480px;
$max-sm: 640px;
$max-md: 768px;
$max-md2: 1024px;
$max-lg: 1200px;
$max-xl: 1366px;
