@import 'sizing.scss';

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Regular.eot');
  src: url('/assets/fonts/Overpass-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-SemiBold.eot');
  src: url('/assets/fonts/Overpass-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Bold.eot');
  src: url('/assets/fonts/Overpass-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Black.eot');
  src: url('/assets/fonts/Overpass-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

* {
  font-family: 'Overpass', sans-serif;
  --ion-font-family: 'Overpass', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: 700;
  line-height: 1.25em;
  white-space: pre-line;
}

h1 {
  font-size: $size-base * 2.25;
  line-height: 2em;
}

h2 {
  font-size: $size-base * 1.65;
  line-height: 2em;
}

h3 {
  font-size: $size-base * 1.5;
}

h4 {
  font-size: $size-base * 1.25;
}

h5 {
  font-size: $size-base * 1.125;
}

h6 {
  font-size: $size-base;
}

p {
  line-height: 1.5em;
  font-size: $size-base;
  font-weight: 400;
}
