@import '../sizing.scss';

.c-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36rem;

  &--full {
    min-height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin: 0;
    color: var(--color-gray-500);
    font-size: 1.1 * $size-base;
    font-weight: normal;
    text-align: center;
  }

  @media screen and (max-width: $break-sm) {
    flex: 1 0 auto;
    height: 100%;
    min-height: auto;
    background: transparent;
    border: 0;

    &__text {
      font-size: $size-md;
    }
  }
}
