.c-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 36rem;
  flex: 1 0 auto;

  &--sm {
    min-height: 18rem;
  }

  &--xs {
    min-height: 12rem;
  }

  @media screen and (max-width: $break-sm) {
    flex: 1 0 auto;
    height: 100%;
    min-height: auto;
  }
}
