@import '../sizing.scss';
@import '../variables.scss';

.c-list {
  display: flex;
  flex-direction: column;
  margin: 0 -#{$size-page};
  padding: 0;

  &--wrap {
    .c-list__label {
      display: block;
      white-space: nowrap;
    }
  }

  &--strong {
    .c-list__label {
      font-weight: 600;
    }
  }

  &--recommendation {
    text-align: left;

    .c-list__item {
      align-items: flex-start;
      padding: $size-sm $size-page;
      border-bottom: 0 !important;

      &:hover {
        background: none;
      }
    }

    .c-list__icon {
      margin-top: 0;

      .c-icon {
        --color: var(--color-secondary-700);
      }
    }

    .c-list__label {
      -webkit-line-clamp: unset;
      overflow: visible;
    }

    &-lg {
      @media screen and (min-width: $break-md) {
        .c-list__label {
          font-size: 1.25 * $size-base;
        }

        .c-icon {
          --size: 3rem;
          --font-size: 1.5rem;
        }
      }
    }
  }

  &--not-clickable {
    .c-list__item {
      &:hover {
        background: none;
      }
    }
  }

  &--simple {
    flex: 1 0 auto;
    margin: 0;

    .c-list__item {
      padding: $size-md 0;
    }
  }

  &--regular {
    flex: 1 0 auto;
    margin: 0 -#{$size-md};

    .c-list__item {
      padding: $size-md $size-page;
    }
  }

  &--bare {
    margin: 0;

    .c-list__item {
      align-items: flex-start;
      border-bottom: 0 !important;
      padding: 0.75 * $size-base 0;

      &:hover {
        background: none;
      }
    }

    .c-list__icon {
      margin-top: -2px;
      color: var(--color-secondary-700);
    }

    &.c-list--red .c-list__icon {
      color: #ff4955;
    }
  }

  &--circle {
    .c-list__icon {
      background-color: #e7faf1;
      border-radius: 50%;
      padding: 3px;
      font-size: 1.2rem;
    }

    &.c-list--red .c-list__icon {
      background-color: #ffeeeb;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: $size-page;
    outline: 0;

    &:not(:last-of-type) {
      border-bottom: solid 1px var(--color-gray-300);
    }

    &:hover {
      background: var(--color-secondary-100);
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    color: inherit;
    text-decoration: none;
  }

  &__icon {
    flex: 0 0 auto;
    margin: -$size-sm 0;
    margin-right: $size-md;
    font-size: 1.5rem;

    .c-icon {
      --color: var(--color-black);
    }
  }

  &__label {
    display: -webkit-box;
    flex: 1 1 auto;
    margin: 0;
    padding-right: $size-base * 1.412;
  }

  &__image {
    --size: #{2.5 * $size-base};

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    margin: -$size-sm 0;
    margin-right: $size-md;
    border-radius: 1.5 * $size-sm;
    overflow: hidden;

    img {
      display: block;
      flex: 1 0 auto;
      max-width: none;
      max-height: var(--size);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
    }
  }

  &__badge-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 2.5 * $size-base;
  }

  &__badge {
    position: absolute;
    right: 10px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    background-color: var(--color-secondary-300);
    border-radius: 50%;
    border: 1px solid var(--color-white);
    font-size: 12px;
    font-weight: bold;
  }

  &__sublabel {
    margin: 0;
    margin-left: $size-sm;
    color: var(--color-gray-700);
    font-size: 0.9 * $size-base;

    &--error {
      display: flex;
      align-items: center;
      color: var(--color-red);

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        margin-right: $size-xs;
        margin-top: -2px;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve' fill='%23f15f61'%3E%3Cg%3E%3Cg%3E%3Cpath d='M235.4,172.2c0-11.4,9.3-19.9,20.5-19.9c11.4,0,20.7,8.5,20.7,19.9s-9.3,20-20.7,20C244.7,192.2,235.4,183.6,235.4,172.2z M236.8,207.9H275V352h-38.2V207.9z'/%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M256,76c48.1,0,93.3,18.7,127.3,52.7S436,207.9,436,256s-18.7,93.3-52.7,127.3S304.1,436,256,436 c-48.1,0-93.3-18.7-127.3-52.7C94.7,349.3,76,304.1,76,256s18.7-93.3,52.7-127.3C162.7,94.7,207.9,76,256,76 M256,48 C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48L256,48z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: contain;
      }
    }
  }

  &__action {
    margin-left: $size-md;
  }
}
