@import '../sizing.scss';
@import '../variables.scss';

.c-ion-flyover {
  --width: auto;
  --height: auto;
  --max-height: auto;
  overflow: auto;
  overflow-x: hidden;
  position: fixed;

  align-items: flex-start;
  overflow: auto;

  .ion-page {
    position: relative;
    contain: unset;
    height: 100vh;
    min-height: 100%;
    overflow: auto;
  }

  ::part(backdrop) {
    display: none;
  }

  ::part(content) {
    --background: transparent;
    width: 100%;
    height: 100%;
    max-width: none;
    overflow: visible;
    box-shadow: none;
  }
}

.c-flyover-backdrop {
  position: absolute;
  top: -150px; // include an offset to make the overlay not look weird
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.3;
  cursor: pointer;
}

.c-flyover {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100vw;
  min-height: 100vh;
  background: white;
  padding-bottom: max(
    #{$size-sm},
    calc(#{$size-sm} + constant(safe-area-inset-bottom))
  ); // for ios 11.1
  padding-bottom: max(#{$size-sm}, calc(#{$size-sm} + env(safe-area-inset-bottom)));
  padding: $size-md;
  border-radius: #{1.5 * $size-base};

  &__close {
    --color: var(--color-black);
    position: fixed;
    z-index: 2;
    top: calc(env(safe-area-inset-top, 0) + #{$size-md});
    right: $size-md;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size-lg;
    height: $size-lg;
    background: var(--color-gray-200);
    border-radius: 50%;
    padding: 0;
    border: 0;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 2px;
      background: var(--color);
      transform: rotate(45deg);
      transform-origin: center;
    }

    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 2px;
      background: var(--color);
      transform: rotate(-45deg);
      transform-origin: center;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      --color: var(--color-primary);
    }
  }

  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(250px + env(safe-area-inset-top, 0));
    margin: -$size-page;
    margin-bottom: $size-md;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url('/assets/images/wave-partial.svg');
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 100% auto;
    }

    img {
      flex: 1 0 auto;
      min-width: 100%;
      max-width: none;
      min-height: 100%;
      max-height: 100%;
      width: auto;
    }
  }

  &__subtitle {
    margin-bottom: $size-xs;
    font-size: 0.9 * $size-base;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__title {
    margin-bottom: $size-md;
    font-weight: 700;
    line-height: 1.4em;
  }

  &__description {
    width: 100%;
    color: var(--color-black);
  }
}

@media (min-width: $break-sm) {
  .c-ion-flyover {
    .ion-page {
      height: auto;
      overflow: unset;
      align-items: center;
      justify-content: center;
    }

    ::part(content) {
      --border-radius: #{1.5 * $size-base};
      padding: $size-lg 0;
    }
  }

  .c-flyover {
    border-radius: 1.5 * $size-base;
    overflow: hidden;
    padding: $size-lg;
    margin: $size-lg auto;

    ::part(content) {
      border-radius: 1.5 * $size-base;
      overflow: hidden;
      padding: $size-lg;
    }

    &__close {
      position: absolute;
    }

    &__image {
      margin: -$size-lg;
      margin-bottom: 0;
    }
  }
}
