@import '../sizing.scss';

.c-token {
  &__icon {
    font-size: 24px;
    color: var(--color-secondary-700);
  }

  &__title {
    margin-top: $size-md;
    text-align: left;
    line-height: 1em !important;

    @media screen and (max-width: $break-md) {
      margin-bottom: $size-md;
    }
  }

  &--last {
    @media screen and (max-width: $break-md) {
      border-bottom: 1px solid var(--color-gray-300);
    }
  }

  @media screen and (min-width: $break-md) {
    padding: 2rem;
    width: 20rem;
    background-color: var(--color-secondary-100);
    border-radius: $size-base;
  }

  @media screen and (max-width: $break-md) {
    padding-top: $size-md;
    border-top: 1px solid var(--color-gray-300);
    padding-left: 2.5 * $size-base;
    margin: 0 -1 * $size-page;
  }
}
