@import '../sizing.scss';

.c-terms-footer {
  display: none;
  justify-content: center;
  align-self: flex-start;
  padding-top: $size-md;
  color: var(--color-gray-500);
  text-align: center;

  @media screen and (min-width: $break-sm) {
    display: flex;
  }

  .c-terms-footer__link {
    margin-right: $size-lg;
    color: var(--color-black);
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }
}
