@import '../sizing.scss';

.c-tabbar {
  &.tab-bar-translucent {
    --background: rgba(255, 255, 255, 0.6);
    backdrop-filter: saturate(180%) blur(10px);
  }

  ion-tab-button {
    --color: var(--color-black);
    --color-selected: var(--color-primary);
  }
}
